<template>
  <v-card flat>
    <v-card-title>Territorial definition</v-card-title>
    <v-card-text>
      <v-radio-group
        v-model="mandatoryChoice"
        row
      >
        <v-radio
          label="File"
          :ripple="true"
          value="file"
          color="color_green"
        />
        <v-radio
          label="Drawing"
          :ripple="true"
          value="drawing"
          color="color_green"
        />
        <v-radio
          label="BBox"
          :ripple="true"
          value="bbox"
          color="color_green"
        />
      </v-radio-group>
      <div v-if="mandatoryChoice === 'file'">
        <v-row>
          <v-col>
            <v-file-input
              v-model="geoJSON"
              accept=".geojson"
              placeholder="Upload your GeoJSON file"
              label="File input"
              prepend-inner-icon="mdi-earth"
              outlined
              truncate-length="50"
              @change="confirmFile"
              @click:clear="closeFileMap"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-alert
              :value="alert"
              color="red accent-4"
              dark
              transition="scale-transition"
            >
              GeoJSON not valid.
            </v-alert>
          </v-col>
        </v-row>
        <div v-if="renderFileMap == true && !alert">
          <v-row>
            <v-col>
              <file-map
                @closeMap="closeFileMap"
                @confirmPolygon="confirmDomain"
                @errorMap="errorMapEvent"
              />
            </v-col>
          </v-row>
        </div>
      </div>
      <div v-else-if="mandatoryChoice === 'drawing'">
        <draw-map @confirmPolygon="confirmDomain" />
      </div>
      <div v-else>
        <div v-if="renderBBoxMap == false">
          <v-row>
            <v-col>
              <v-text-field
                v-model="minLon"
                label="Minimum Longitude"
                outlined
              />
            </v-col>
            <v-col>
              <v-text-field
                v-model="minLat"
                label="Minimum Latitude"
                outlined
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                v-model="maxLon"
                label="Maximum Longitude"
                outlined
              />
            </v-col>
            <v-col>
              <v-text-field
                v-model="maxLat"
                label="Maximum Latitude"
                outlined
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn
                dark
                color="color_green"
                @click="showBBoxPreview"
              >
                Show Preview
              </v-btn>
            </v-col>
          </v-row>
        </div>
        <div v-if="renderBBoxMap == true">
          <v-row>
            <v-col>
              <bbox-map
                @closeMap="closeBBoxMap"
                @confirmPolygon="confirmDomain"
              />
            </v-col>
          </v-row>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  components: {
    DrawMap: () => import('@/components/domain/DrawMap'),
    FileMap: () => import('@/components/domain/FileMap'),
    bboxMap: () => import('@/components/domain/BBoxMap')
  },
  data() {
    return {
      mandatoryChoice: 'file',
      geoJSON: null,
      coordinates: {},
      renderFileMap: false,
      renderBBoxMap: false,
      minLon: '',
      minLat: '',
      maxLon: '',
      maxLat: '',
      stringaPoligono: '',
      points: [],
      alert: false
    };
  },

  computed: {
    ...mapState(['domain'])
  },

  created() {
    if (this.domain.isModifingDomain == true) {
      this.mandatoryChoice = 'drawing';
    }

    if (this.domain.polyString != '') {
      this.stringaPoligono = this.domain.polyString.match(/\(\(\((.*)\)\)\)/)[1]; //prende la stringa all'interno di ((( e )))
      this.stringaPoligono = this.stringaPoligono.split(',');

      if (this.stringaPoligono.length == 5) {
        this.stringaPoligono.forEach(e => {
          this.points.push(e.split(' '));
        });
        this.points.pop(); //rimuove l'ultimo elemento perchè uguale al primo

        if (this.isRectangleOrSquare(this.points)) {
          this.mandatoryChoice = 'bbox';
          [this.minLon, this.minLat] = this.points[0];
          [this.maxLon, this.maxLat] = this.points[2];
        }
      }
    }
  },

  methods: {
    ...mapActions(['storeToastMessage', 'storeDomainBbox']),
    confirmFile() {
      if (!this.geoJSON) {
        this.storeToastMessage({
          text: 'please choose a file',
          type: 'warning'
        });
      } else {
        const reader = new FileReader();

        reader.onload = () => {
          try {
            this.coordinates = JSON.parse(reader.result);
            this.renderFileMap = true;
            this.alert = false;
          } catch (err) {
            this.alert = true;
          }

          this.domain.geoJSON = JSON.parse(JSON.stringify(this.coordinates));
        };

        reader.readAsText(this.geoJSON);
      }
    },

    closeFileMap() {
      this.renderFileMap = false;
      this.alert = false;
    },

    showBBoxPreview() {
      this.domain.BBox = [
        [
          [parseFloat(this.minLon), parseFloat(this.minLat)],
          [parseFloat(this.maxLon), parseFloat(this.minLat)],
          [parseFloat(this.maxLon), parseFloat(this.maxLat)],
          [parseFloat(this.minLon), parseFloat(this.maxLat)],
          [parseFloat(this.minLon), parseFloat(this.minLat)]
        ]
      ];

      this.renderBBoxMap = true;
    },

    closeBBoxMap() {
      this.renderBBoxMap = false;
    },

    errorMapEvent() {
      this.alert = true;
    },

    confirmDomain() {
      this.$emit('confirmDomainData');
    },

    distance(point1, point2) {
      return Math.sqrt((point2[0] - point1[0]) ** 2 + (point2[1] - point1[1]) ** 2);
    },

    isRectangleOrSquare(points) {
      const d = [
        this.distance(points[0], points[1]),
        this.distance(points[0], points[2]),
        this.distance(points[0], points[3]),
        this.distance(points[1], points[2]),
        this.distance(points[1], points[3]),
        this.distance(points[2], points[3])
      ];

      d.sort((a, b) => a - b);

      return (d[0] === d[1] && d[1] === d[2] && d[2] === d[3] && d[4] === d[5]) || (d[0] === d[1] && d[2] === d[3] && d[4] === d[5]);
    }
  }
};
</script>

<style lang="scss" scoped></style>
